<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="PRODUCT"
        :filter="filter"
        placeholder="Search Name, Barcode, Article No."
        @sidebar="sidebarFilter"
        @search="handleSearch"
        :hasDropdown="true"
        @downloadTemplate="downloadTemplate"
        @exportFile="exportFile"
        @importFile="importFile"
        routerPath="/product/detail/0"
      />

      <TableProductPromotionList
        class="mt-3"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :showing="showing"
        :showingTo="showingTo"
        :rows="rows"
        :pageOptions="pageOptions"
        :filter="filter"
        @handleChangeTake="handleChangeTake"
        @page="page"
      />
      <SidebarFilterProductPromotion
        ref="SidebarFilterProduct"
        :productType="productType"
        :status="status"
        :filter="filter"
        @submitFilter="submitFilter"
      />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";

import TableProductPromotionList from "@/components/productPromotion/TableProductPromotionList";
import SidebarFilterProductPromotion from "@/components/productPromotion/SidebarFilterProductPromotion";
export default {
  components: {
    TableProductPromotionList,
    SidebarFilterProductPromotion,
    OtherLoading,
  },
  data() {
    return {
      filter: {
        search: null,
        product_type: [],
        status: [],
        take: 10,
        page: 1,
      },
      fields: [
        {
          label: "Image",
          key: "image_url",
          thStyle: { width: "15%" },
          tdClass: "p-3",
        },
        {
          label: "Name/Code/Type",
          key: "name",
          thStyle: { width: "35%" },
        },
        {
          label: "AH",
          key: "ah_4_id",
        },
        {
          label: "Barcode",
          key: "barcode",
        },
        {
          label: "Product Type",
          key: "product_type",
        },
        {
          label: "Price",
          key: "price",
        },
        {
          label: "Status",
          key: "status",
        },
      ],
      items: [],
      isBusy: true,
      showing: 1,
      showingTo: 10,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      productType: [
        {
          id: "ZFIN",
          name: "ZFIN",
        },
        {
          id: "ZSAM",
          name: "ZSAM",
        },
        {
          id: "ZLPR",
          name: "ZLPR",
        },
      ],
      status: [
        {
          id: 1,
          name: "Active",
        },
        {
          id: 0,
          name: "Inactive",
        },
      ],
      isLoading: false,
      filterExport: {
        search: null,
        product_type: [],
        status: [],
        from_price: 0,
        to_price: 0,
      },
    };
  },
  async created() {
    await this.getProductList();
  },
  methods: {
    async getProductList() {
      this.isBusy = true;
      await this.$store.dispatch("getProductPromotionList", this.filter);
      const data = this.$store.state.product.stateProductPromotionList;
      if (data.result === 1) {
        this.items = data.detail.product_list;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    sidebarFilter() {
      this.$refs.SidebarFilterProduct.show();
    },
    handleSearch() {
      this.filter.page = 1;
      this.getProductList();
    },
    handleChangeTake(filter) {
      this.filter = filter;
      this.getProductList();
    },
    page(filter) {
      this.filter = filter;
      this.getProductList();
    },
    submitFilter(filter) {
      this.filter = filter;
      this.getProductList();
    },
    createProduct() {
      this.$router.push("/product/detail/0");
    },
    async downloadTemplate() {
      this.isLoading = true;
      await this.$store.dispatch("downloadTemplateProduct");
      const data = this.$store.state.product.stateDownloadTemplateProduct;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.isLoading = false;
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Product-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async exportFile() {
      this.isLoading = true;
      this.filterExport.search = this.filter.search;
      this.filterExport.product_type = this.filter.product_type;
      this.filterExport.status = this.filter.status;
      await this.$store.dispatch("exportProductList", this.filterExport);
      const data = this.$store.state.product.stateExportProductList;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Product-` + dateExcel + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    importFile() {
      this.$router.push("/product/imporfile");
    },
  },
};
</script>
