import { render, staticRenderFns } from "./TableProductPromotionList.vue?vue&type=template&id=09e8ccc7&scoped=true&"
import script from "./TableProductPromotionList.vue?vue&type=script&lang=js&"
export * from "./TableProductPromotionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e8ccc7",
  null
  
)

export default component.exports